<template>
  <section >
    <!-- <pm-Breadcrumb :home="home" :model="items_bread" /> -->
    <div class="col-md-12">
      <h2 class="titulo-links-escolha">Editar Servidor</h2>
    </div>
    <div class="card card-steps">
      <Step2 :items="items"/>
    </div>
    <br>
    <infoLoginDados   @prevPage="prevPage"   @nextPage="nextPage" :pg="pg" :dadosPessoa="dadosPessoa" :id="id"/>


  </section >
</template>

<script>
import Step2 from "@/components/steps2.vue";
import infoLoginDados from '@/components/servidores/infoLoginDados.vue';

export default {
  props: {
    id:{},
    basic:{},
    pg:{},
  },
  components: {
    Step2,
    infoLoginDados,
  },
  name: "login-pessoal-servidor-editar",
  data() {
    return {
      home: { icon: "pi pi-home", to: "/admin/painel-adm" },
      items_bread: [{ label: "Editar Servidor" }],
      dadosPessoa: [],
      items: [    
        {
          number: "2",
          label: "Dados de acesso ao sistema",
          to: "2",
          active: 1,
        },
      ],
    };
  },
  methods: {
    prevPage(info) {
      this.$router.push({
        name: "coordenador-servidor-basic-info-editar",
        params: { basic: JSON.stringify(info) },
      });
    },
    nextPage(info) {
      this.$router.push({
        name: "coordenador-endereco-contato-servidor-editar",
        params: { basic: JSON.stringify(info) },
      });
    },
  },
  beforeMount() {
    if (this.basic != undefined) {
      const nv = JSON.parse(this.basic);
      this.dadosPessoa = nv.info;
    }
  },
};
</script>

<style>
.row.row-inside {
  max-width: none;
}

.form-control:focus {
  background-color: #f6f7f6;
  border-color: #673ab7;
  box-shadow: none;
}
.form-control {
  background-color: #f6f7f6;
  border: 1px solid #f6f6f6;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.va-input-wrapper,
fieldset {
  margin-bottom: 0.5rem;
}
</style>
